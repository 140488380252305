const vehicle_order_statuses = {
    name: 'vehicle_order_statuses',
    title: 'Статусы заказов транспорта',
    url: '/refs/vehicle_order_statuses',
    include: [],
    filterBy: ['title'],
    perms: {
        list: 'v1.vehicle_order_statuses.list',
        read: 'v1.vehicle_order_statuses.read',
        create: 'v1.vehicle_order_statuses.create',
        update: 'v1.vehicle_order_statuses.update',
        delete: 'v1.vehicle_order_statuses.delete',
    },
    listColumns: [
        {
            name: 'title',
            required: true,
            label: 'Наименование',
            align: 'left',
            field: 'title',
            sortable: true,
        },
        {
            name: 'is_init',
            required: true,
            label: 'Первый статус',
            align: 'left',
            field: (row) => row.is_init ? 'Да' : 'Нет',
            sortable: true,
        },
        {
            name: 'is_for_order',
            required: true,
            label: 'Для заказа',
            align: 'left',
            field: (row) => row.is_for_order ? 'Да' : 'Нет',
            sortable: true,
        },
        {
            name: 'is_for_vehicle',
            required: true,
            label: 'Для ТС',
            align: 'left',
            field: (row) => row.is_for_vehicle ? 'Да' : 'Нет',
            sortable: true,
        },
        {
            name: 'can_changed_by_driver',
            required: true,
            label: 'Меняет водитель',
            align: 'left',
            field: (row) => row.can_changed_by_driver ? 'Да' : 'Нет',
            sortable: true,
        },
        {
            name: 'can_changed_by_mechanic',
            required: true,
            label: 'Меняет механик',
            align: 'left',
            field: (row) => row.can_changed_by_mechanic ? 'Да' : 'Нет',
            sortable: true,
        },
        {
            name: 'is_vehicle_active',
            required: true,
            label: 'Блокирует ТС',
            align: 'left',
            field: (row) => row.is_vehicle_active ? 'Да' : 'Нет',
            sortable: true,
        },
    ],
    editColumns: [
        {
            name: 'title',
            required: true,
            label: 'Наименование',
            align: 'left',
            field: 'title',
        },
        {
            name: 'code',
            required: true,
            label: 'Код статуса',
            align: 'left',
            field: 'code',
        },
        {
            name: 'is_init',
            required: true,
            label: 'Первый статус',
            align: 'left',
            field: 'is_init',
            type: 'checkbox',
        },
        {
            name: 'is_for_order',
            required: true,
            label: 'Для заказа',
            align: 'left',
            field: 'is_for_order',
            type: 'checkbox',
        },
        {
            name: 'is_for_vehicle',
            required: true,
            label: 'Для ТС',
            align: 'left',
            field: 'is_for_vehicle',
            type: 'checkbox',
        },
        {
            name: 'can_changed_by_driver',
            required: true,
            label: 'Меняет водитель',
            align: 'left',
            field: 'can_changed_by_driver',
            type: 'checkbox',
        },
        {
            name: 'can_changed_by_mechanic',
            required: true,
            label: 'Меняет механик',
            align: 'left',
            field: 'can_changed_by_mechanic',
            type: 'checkbox',
        },
        {
            name: 'is_vehicle_active',
            required: true,
            label: 'Блокирует ТС',
            align: 'left',
            field: 'is_vehicle_active',
            type: 'checkbox',
        },
        {
            name: 'meta',
            label: 'Мета-информация',
            align: 'left',
            field: 'meta',
            type: 'textarea',
            meta: {
                prepareFrom: (val) => {
                    try {
                        return JSON.stringify(val);
                    } catch {
                        return val;
                    }
                },
                prepareTo: (val) => {
                    try {
                        return JSON.parse(val);
                    } catch {
                        return val;
                    }
                },
            },
            sortable: true,
        },
    ],
};

export {
    vehicle_order_statuses,
};
